import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { superLight, brightSky, tychoNavy } from '../../utils/theme'
import Mask from '../../atoms/Mask'

const StyledContainer = styled.div`
 position: absolute;
`

const ButtonsContainer = styled.div`
 z-index: 999999;
 position: absolute;
 max-height: 160px;
 overflow-x: hidden;
 background: white;
 border-radius: 4px;
 box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
  0 1px 10px 0 rgba(0, 0, 0, 0.12);

 &::-webkit-scrollbar   {
  width: 6px;
 }
 &::-webkit-scrollbar-thumb  {
  background: rgba(95, 99, 104, 0.45);
 }
 &::-webkit-scrollbar-track {
  border-radius: 10px;
 }
`

const StyledButton = styled.button`
 width: 67px;
 max-width: 67px;
 min-width: 67px;
 height: 32px;
 line-height: 32px;
 padding-right: 8px;
 user-select: none;
 cursor: pointer;
 outline: none;
 border: none;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 display: block;
 text-align: left;
 text-decoration: none;
 position: relative;
 background: white;
 font-size: 14px;

 background: ${({ selected }) => (selected ? brightSky : 'white')};

 &:hover {
  background: ${superLight};
 }
`

const MainButton = styled.button`
 line-height: 32px;
 padding-right: 12px;
 padding-left: 6px;
 margin-left: -6px;
 user-select: none;
 cursor: pointer;
 outline: none;
 border: none;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
 display: block;
 text-align: left;
 text-decoration: none;
 position: relative;
 background: white;
 font-size: 16px;
 border-radius: 4px;
 color: ${tychoNavy};

 &:hover {
  background: ${superLight};
 }

 &:after {
  content: '';
  position: relative;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${tychoNavy};
  top: 10px;
  right: -6px;

  transform: rotate(180deg);
 }
`

export default function SelectContainer({
 marks,
 units,
 minValue,
 maxValue,
 value,
 setValue,
 className
}) {
 let allValues = []

 if (marks) {
  allValues = marks.map(mark => mark?.value)
} else {
  for (var i = minValue; i <= maxValue; i++) {
    allValues.push(i)
  }
 }
 const ContainerRef = useRef(null)
 const itemsRef = useRef([])
 useEffect(() => {
  let selectedItem = itemsRef.current.filter(
   elem => elem?.innerHTML === value.toString()
  )
  ContainerRef.current.scrollTop =
   selectedItem[0]?.offsetTop - selectedItem[0]?.clientHeight
  itemsRef.current = itemsRef.current.slice(0, allValues.length)
 }, [value, allValues])

 const [selectContainerHidden, setSelectContainerHidden] = useState(true)
 const hideSelectContainer = () =>
  setSelectContainerHidden(!selectContainerHidden)

 return (
  <div style={{ position: 'relative' }} className={className}>
   <MainButton onClick={hideSelectContainer}>{value + ' ' + units}</MainButton>

   <StyledContainer hidden={selectContainerHidden}>
    <ButtonsContainer ref={ContainerRef} onClick={hideSelectContainer}>
     {allValues.map((val, i) => (
      <StyledButton
       ref={el => (itemsRef.current[i] = el)}
       selected={value === val}
       onClick={() => setValue(val)}
       key={val}
      >
       {val}
      </StyledButton>
     ))}
    </ButtonsContainer>
    <Mask
     white
     onClick={hideSelectContainer}
     visible={!selectContainerHidden}
    />
   </StyledContainer>
  </div>
 )
}
