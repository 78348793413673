import React from 'react'
import KitContents from '../../molecules/KitContents'
import TechDrawings from '../../molecules/TechDrawings'
import Manuals from '../../molecules/Manuals'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { germany } from '../../initial/vars'

const Content = (product) => {

  const isW50System = (product?.wordpress_id === 2559 || product?.wordpress_id === 2589)

  const { t } = useTranslation();
  var regexHtmlTags = /(<([^>]+)>)/ig
  const description = product.short_description.replace(regexHtmlTags, "").trim()
  const goodToKnow = product.acf.additional_txt.replace(regexHtmlTags, "").trim()

  return [
    product.acf.image_1 && {
      sectionTitle: t('Zawartość zestawu'),
      sectionContent: <KitContents product={product} />
    },
    product.short_description && {
      sectionTitle: t('Główne cechy'),
      sectionContent:
        <ReactMarkdown className="section_content" children={t(`${description}`)} />
    },
    product.acf.additional_txt && {
      sectionTitle: t('Przydatne informacje'),
      sectionContent:
        <ReactMarkdown className="section_content" children={t(`${goodToKnow}`)} />
    },
    product.acf.tech_image_1 && {
      sectionTitle: t('Rysunki techniczne'),
      sectionContent: <TechDrawings product={product} />
    },
    (product.acf.man_1_PL || product.acf.man_1_EN) && {
      sectionTitle: t('Do pobrania'),
      sectionContent: <Manuals product={product} />
    },
    {
      sectionTitle: t('Gwarancja, zwroty, dostawa'),
      sectionContent:
        <div className="section_content">
          <p> {t('warranty_info')} </p>
          <p> {t('return_info')} </p>
          <p> {t(isW50System ? 'shipping_info_1B' : 'shipping_info_1')}. <br />
            {!germany && <>{t(isW50System ? 'shipping_info_4' : 'shipping_info_2')}. <br /> </>}
            {t('shipping_info_3')}
          </p>
        </div>

    },
  ]
}


export default Content 