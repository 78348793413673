import React, { useContext, useState, useEffect } from 'react'
import Choice from './Choice'
import Slider from './Slider'
import Spacer from '../../atoms/Spacer'
import GridWrapper from '../../atoms/GridWrapper'
import Col from '../../atoms/Col'
import RegularTypo from '../../typography/RegularTypo'
import AddProduct from './AddProduct'
import { UsersContext } from '../../context'
import PropTypes from 'prop-types'
import withLocation from '../../withLocation'
import { useQueryParam, NumberParam } from 'use-query-params'
import { Helmet } from 'react-helmet'
import {
 parameterizedTrack,
 isSlidingSystemCheck,
 isSlidingSystemLCheck,
 isSlidingSystemWCheck,
 isSlidingSystemW50Check,
 isFoldingSystemCheck,
 isDoubleSystemCheck,
 isSimpleFasciaCheck,
 isRegularTrackCheck,
 totalPrice,
 isConfigurableProduct,
 formatPriceMain,
 getShippingCost,
 formatPrice,
 getCurrency,
 exchange,
 getCurrencySymbol
} from '../../utils/utils'
import Text from '../../atoms/Text'
import { useTranslation } from 'react-i18next'
import { germany } from '../../initial/vars'
import { breakpoints } from '../../utils/theme'
import styled from 'styled-components'

const initialCountry = germany ? 'DE' : 'PL'

const RegularTypoContainer = styled.div`
 line-height: 1.5;
 font-size: 16px;

 @media (max-width: ${breakpoints.mobile}px) {
  font-size: 14px;
 }
`

function Configurator({ product }) {
 const {
  simpleProduct,
  configuredProduct,
  dmp,
  setDmp,
  qty,
  setQty,
  trackLength,
  setTrackLength,
  mountingWall,
  setMountingWall,
  doorThickness,
  setDoorThickness,
  aluFsc,
  setAluFsc,
  aluFscSimple,
  setAluFscSimple,
  spcr,
  setSpcr,
  woodedFasciaBrkt,
  setWoodedFasciaBrkt,
  synchro,
  setSynchro,
  cart,
  region
 } = useContext(UsersContext)

 const [_region, set_region] = useState(null)

 const baseKitName = product.node.name
 const simplePrice = germany ? product.node.acf.euro_price : product.node.price
 const simpleSku = product.node.sku
 const kitId = product.node.wordpress_id
 const prodImage = product.node.images[0].localFile.url

 const isSlidingSystem = isSlidingSystemCheck(kitId)
 const isSlidingSystemL = isSlidingSystemLCheck(kitId)
 const isSlidingSystemW = isSlidingSystemWCheck(kitId)
 const isSlidingSystemW50 = isSlidingSystemW50Check(kitId)
 const isFoldingSystem = isFoldingSystemCheck(kitId)
 const isDoubleSystem = isDoubleSystemCheck(kitId)
 const isSimpleFascia = isSimpleFasciaCheck(kitId)
 const isRegularTrack = isRegularTrackCheck(kitId)

 const isG100System = kitId === 545
 const isG80System = kitId === 555
 const isW40System = kitId === 976
 const isW45System = kitId === 2488
 const isW50System = kitId === 2559 || kitId === 2589

 const hasFixedValues = isW45System || isW50System

 const hasTrack =
  isSlidingSystem ||
  isFoldingSystem ||
  isDoubleSystem ||
  isG100System ||
  isG80System ||
  isSlidingSystemL ||
  isSlidingSystemW ||
  isSlidingSystemW50

 const slidingS = isSlidingSystem || isDoubleSystem
 const slidingSG =
  isSlidingSystem || isDoubleSystem || isG100System || isG80System

 const prodd = configuredProduct(
  qty,
  dmp,
  synchro,
  trackLength,
  mountingWall,
  baseKitName,
  prodImage,
  kitId,
  doorThickness,
  woodedFasciaBrkt,
  aluFsc,
  aluFscSimple,
  spcr
 ).readyProduct

 const price = prodd.kitBase_S60
  ? totalPrice(prodd) / prodd.kitBase_S60.quantity
  : germany
  ? product.node.acf.euro_price
  : product.node.price

 const [num] = useQueryParam('track', NumberParam)

 useEffect(() => {
  set_region(region)
 }, [region])

 useEffect(() => {
  aluFscSimple ||
  (!mountingWall && woodedFasciaBrkt && !isFoldingSystem && !isG80System)
   ? setSpcr(1)
   : setSpcr(0)
 }, [
  isG80System,
  isFoldingSystem,
  aluFscSimple,
  mountingWall,
  woodedFasciaBrkt
 ])

 useEffect(() => {
  setTrackLength(
   parameterizedTrack(num, isFoldingSystem, isDoubleSystem, isSlidingSystemW50)
  )
 }, [num, setTrackLength, isFoldingSystem, isDoubleSystem])

 useEffect(() => {
  if (isG100System || isG80System) {
   setDoorThickness(0)
  }
 }, [setDoorThickness, isG100System, isG80System])

 const { t } = useTranslation()

 const fixedValues = [
  // { value: 120 },
  // { value: 150 },
  // { value: 180 },
  { value: 200 },
  { value: 300 }
 ]

 const getMinTrack = () => {
  if (isFoldingSystem) {
   return 80
  } else if (isDoubleSystem) {
   return 140
  } else {
   return 100
  }
 }

 const getMaxTrack = () => {
  if (isFoldingSystem) {
   return germany ? 200 : 200
  } else if (isSlidingSystemL) {
   return germany ? 200 : 240
  } else if (isSlidingSystemW) {
   return germany ? 200 : 240
  } else if (isSlidingSystemW50) {
   return germany ? 200 : 300
  } else {
   return germany ? 200 : 300
  }
 }

 const lengthOver250 = () => {
  if (trackLength >= 250 || isW50System) {
   return true
  } else {
   return cart.some(prod => prod?.readyProduct?.track?.length >= 250)
  }
 }

 const defaultPrice =
  (germany ? 19 : 85.6) + parseFloat(prodd?.kitBase_S60?.regular_price) ||
  (germany ? product?.node?.acf?.euro_price : product?.node?.price)

 return (
  <div style={{ marginTop: 18 }}>
   <Helmet>
    <script
     async
     defer
     id="product_schema"
     data-n={t(`product::${product.node.name}`)}
     data-i={product?.node?.images?.[0]?.localFile?.url}
     data-c={getCurrency(region)}
     data-p={exchange({ price: defaultPrice, region })}
    >
     {` `}
    </script>
   </Helmet>
   <GridWrapper col={4} gutter={0} templateColumns={'1fr 1fr'}>
    <Col span={2} style={{ gridColumn: '1', alignSelf: 'end' }}>
     {_region && (
      <Text
       style={{ lineHeight: '1.125' }}
       text={
        <>
         {region === 'CH' && (
          <span itemProp="priceCurrency" content={getCurrency(region)}>
           {getCurrencySymbol(region)}&nbsp;
          </span>
         )}
         {region === 'UK' && (
          <span itemProp="priceCurrency" content={getCurrency(region)}>
           {getCurrencySymbol(region)}
          </span>
         )}
         <span itemProp="price" content={exchange({ price, region })}>
          {exchange({ price, region })}
         </span>
         {region !== 'CH' && region !== 'UK' && (
          <span itemProp="priceCurrency" content={getCurrency(region)}>
           {' '}
           {getCurrencySymbol(region)}&nbsp;
          </span>
         )}
        </>
       }
      />
     )}
    </Col>
    <Col span={2} style={{ gridColumn: '2' }}>
     {initialCountry === 'PL' ? (
      <RegularTypo
       text={
        t('shipping_info_0') +
        formatPrice({
         price: getShippingCost({ lengthOver250: lengthOver250() }),
         region
        })
       }
      />
     ) : (
      <RegularTypoContainer>
       {t('shipping_info_0')} {formatPriceMain({ price: 4.7, region })}
      </RegularTypoContainer>
     )}

     <RegularTypo
      text={t(_region === 'DE' ? 'shipping_info_2B' : isW50System ? 'shipping_info_4' : 'shipping_info_2')}
     />
    </Col>
   </GridWrapper>

   <Spacer space={18} />

   {isConfigurableProduct(product) && (
    <>
     {hasTrack ? (
      <Slider
       label={t('Prowadnica')}
       units="cm"
       minValue={getMinTrack()}
       maxValue={getMaxTrack()}
       value={trackLength}
       setValue={setTrackLength}
       marks={hasFixedValues ? fixedValues : null}
      />
     ) : null}

     {isSimpleFascia || isRegularTrack ? (
      <Slider
       label={t('Długość')}
       units="cm"
       minValue={100}
       maxValue={germany ? 200 : 300}
       value={trackLength}
       setValue={setTrackLength}
      />
     ) : null}

     {slidingSG ? (
      <Choice
       label={t('Mocowanie')}
       enumeration={['Do stropu', 'Do ściany']}
       value={mountingWall}
       setValue={setMountingWall}
      />
     ) : null}

     {mountingWall && slidingS ? (
      <Choice
       label={t('Grubość drzwi')}
       enumeration={['16–25 mm', '26–45 mm']}
       value={doorThickness}
       setValue={setDoorThickness}
      />
     ) : null}

     {mountingWall && !woodedFasciaBrkt && !isG80System ? (
      <Choice
       // swtch
       label={t('Maskownica aluminiowa')}
       enumeration={['Nie', 'Srebrna', 'Czarna']}
       value={aluFsc}
       setValue={setAluFsc}
      />
     ) : null}

     {(isSlidingSystem || isDoubleSystem || isG100System) &&
     !mountingWall &&
     !woodedFasciaBrkt ? (
      <Choice
       // swtch
       label={t('Maskownica aluminiowa płaska')}
       enumeration={['Nie', 'Srebrna', 'Czarna']}
       value={aluFscSimple}
       setValue={setAluFscSimple}
      />
     ) : null}

     {!aluFsc &&
     !aluFscSimple &&
     (isSlidingSystem || isFoldingSystem || isDoubleSystem || isG80System) ? (
      <Choice
       label={t('Uchwyt maskownicy drewnianej')}
       swtch
       value={woodedFasciaBrkt}
       setValue={setWoodedFasciaBrkt}
      />
     ) : null}

     {isDoubleSystem && !dmp ? (
      <Choice
       label={t('Otwieranie synchroniczne')}
       swtch
       value={synchro}
       setValue={setSynchro}
      />
     ) : null}

     {(isG100System ||
      isSlidingSystem ||
      isDoubleSystem ||
      isW40System ||
      isSlidingSystemW50) &&
     !synchro ? (
      <Choice
       label={isSlidingSystemW50 ? t('Domykacz') : t('Miękkie domykanie')}
       enumeration={
        isSlidingSystemW50
         ? ['Brak', '2 sztuki']
         : ['Nie', '1 strona', '2 strony']
       }
       value={dmp}
       setValue={setDmp}
      />
     ) : null}

     <Spacer />
    </>
   )}
   <GridWrapper col={5} gutter={16}>
    <input
     type="number"
     min="1"
     max="99"
     value={qty}
     onChange={e => setQty(e.target.value)}
    />

    {isConfigurableProduct(product) ? (
     <AddProduct
      style={{ gridColumn: '2 / 4' }}
      readyProduct={configuredProduct(
       qty,
       dmp,
       synchro,
       trackLength,
       mountingWall,
       baseKitName,
       prodImage,
       kitId,
       doorThickness,
       woodedFasciaBrkt,
       aluFsc,
       aluFscSimple
      )}
     />
    ) : (
     <AddProduct
      style={{ gridColumn: '2 / 4' }}
      simpleProduct={simpleProduct(
       qty,
       baseKitName,
       prodImage,
       simplePrice,
       kitId,
       simpleSku
      )}
     />
    )}
   </GridWrapper>
  </div>
 )
}

Configurator.propTypes = {
 search: PropTypes.object
}

export default withLocation(Configurator)
